<template>
  <div>
    <div class="oa-work-box border">
      <span>
        <el-button @click="exportExcel" size="small" class="oa-button">导出Excel</el-button>
      </span>
      <span>
        <el-input v-model="searchOption.key" class="search-input" size="mini" placeholder="请输入名称搜索">
        </el-input>
        <el-button type="primary" size="mini" @click="search">
          <font-awesome-icon fas icon="search"></font-awesome-icon>&nbsp;查询
        </el-button>
      </span>
    </div>
    <!-- 列表 -->
    <el-table border :data="list" :height="tableHeight" row-key="Id" ref="table" size="small" class="oa-table">
      <el-table-column sortable prop="ActivityName" label="活动名称" width="200"></el-table-column>
      <el-table-column sortable prop="ProjectName" label="项目名称" width="200"></el-table-column>
      <el-table-column prop="WeixinOpenid" label="微信Openid" width="300"></el-table-column>
      <el-table-column prop="WeixinUnionid" label="微信Unionid" width="300"></el-table-column>
      <el-table-column prop="RegisterCode" label="注册码" width="100"></el-table-column>
      <el-table-column prop="Mobile" label="手机号码" width="100"></el-table-column>
      <el-table-column prop="Email" label="邮件地址" width="200"></el-table-column>
      <el-table-column prop="IdCard" label="身份证号码" width="200"></el-table-column>
      <el-table-column sortable prop="IdCardVerify" label="是否实名认证" width="100">
        <template slot-scope="scope">
          {{scope.row.IdCardVerify? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column sortable prop="Name" label="姓名" width="100"></el-table-column>
      <el-table-column sortable prop="FirstName" label="名" width="80"></el-table-column>
      <el-table-column sortable prop="LastName" label="姓" width="80"></el-table-column>
      <el-table-column sortable prop="Company" label="公司" width="200"></el-table-column>
      <el-table-column prop="CompanyAddress" label="公司地址" width="200"></el-table-column>
      <el-table-column sortable prop="Title" label="职务" width="100"></el-table-column>
      <el-table-column sortable prop="Country" label="所在国家" width="100"></el-table-column>
      <el-table-column sortable prop="Province" label="所在省份" width="100"></el-table-column>
      <el-table-column sortable prop="City" label="所在城市" width="100"></el-table-column>
      <el-table-column sortable prop="RegisterTime" label="注册时间" width="140"></el-table-column>
      <el-table-column sortable prop="BuyAmount" label="购买数量" width="100"></el-table-column>
      <el-table-column sortable prop="OutTradeNo" label="付款订单号" width="200"></el-table-column>
      <el-table-column sortable prop="PayStatus" label="付款状态" width="100"></el-table-column>
      <el-table-column sortable prop="PayAmount" label="付款金额" width="100"></el-table-column>
      <el-table-column sortable prop="PayTime" label="付款时间" width="140"></el-table-column>
      <el-table-column sortable prop="TransactionId" label="付款订单号" width="200"></el-table-column>
      <el-table-column show-overflow-tooltip prop="CipherText" label="付款回调信息" width="200"></el-table-column>
      <el-table-column sortable prop="IsSync" label="是否同步到现场" width="120">
        <template slot-scope="scope">
          {{scope.row.IsSync? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column sortable prop="SyncTime" label="同步时间" width="140"></el-table-column>
      <el-table-column sortable prop="IsChecked" label="是否检票" width="100">
        <template slot-scope="scope">
          {{scope.row.IsChecked? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column sortable prop="CheckedTime" label="检票时间" width="140"></el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" @size-change="pageSizeChange"
      @current-change="pageIndexChange">
    </el-pagination>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'

// 活动用户
export default {
  name: 'OARegistrationUser',
  data () {
    return {
      list: [], // 列表
      loading: false, // 加载中
      searchOption: {
        key: '' // 搜索条件（关键字）
      },
      total: 1, // 总数据量
      pageIndex: 1, // 页码
      pageSize: 50 // 页数
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.init())
  },
  computed: {
    tableHeight () {
      // -顶部150元素高度 + 底部分页操作50高度度
      return window.innerHeight - 200
    }
  },
  methods: {
    init () {
      this.list = []
      if (!this.loading) {
        this.get()
      }
    },
    get () {
      this.loading = true
      this.axios.get(`${API.REGISTRATION_USER.URL}/${this.pageIndex}/${this.pageSize}`, {
        params: this.searchOption
      }).then(response => {
        this.total = response.Total
        this.list = response.Items
        this.loading = false
      })
    },
    pageSizeChange (value) {
      this.pageSize = value
      this.$nextTick(() => this.get())
    },
    pageIndexChange (value) {
      this.pageIndex = value
      this.$nextTick(() => this.get())
    },
    search () {
      this.pageIndex = 1
      this.$nextTick(() => this.get())
    },
    moreCommand (command) {
      switch (command) {
        case 'export': this.exportExcel(); break
      }
    },
    exportExcel () {
      this.axios.get(API.REGISTRATION_USER.EXCEL, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '注册用户.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .oa-work-box {
  .search-input {
    width: 200px;
    margin-right: .75rem;
  }
}
</style>
